import React from "react"

import { EmojiSmile, GraphUp, Heart, People } from "react-bootstrap-icons"

import Section from "../../components/Section"
import Container from "../../components/Container"
import StateCard1 from "../../components/cards/StateCard1"
import CountUp from "../../components/CountUp"

const State1 = () => {
  return (
    <>
      <Section className="bg-neutral-900">
        <Container>
          {/* cards */}
          <div className="grid grid-cols-1 gap-16 laptop:gap-8 tablet:grid-cols-2 laptop:grid-cols-4">
            {/* card */}

            <StateCard1
              icon={<Heart />}
              text="Customer Satisfaction"
              title={<CountUp end={98} suffix="%" />}
            />

            <StateCard1
              icon={<People />}
              text="Monthly active user"
              title={<CountUp end={100} suffix="M+" />}
            ></StateCard1>
            <StateCard1
              icon={<GraphUp />}
              text="Annual growth"
              title={<CountUp end={75} suffix="%" />}
            ></StateCard1>
            <StateCard1
              icon={<EmojiSmile />}
              text="Team members"
              title={<CountUp end={50} suffix="+" />}
            ></StateCard1>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default State1
