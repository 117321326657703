import React from "react"
import {
  Award,
  Binoculars,
  PieChart,
  Speedometer2,
} from "react-bootstrap-icons"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import TextSmall from "../../components/TextSmall"

const About1 = () => {
  return (
    <>
      <Section className="overflow-hidden">
        <Container>
          {/* section-wrap */}
          <div className="grid grid-cols-1 laptop:grid-cols-12 ">
            {/* left-part */}
            <div className="pb-20 laptop:col-span-7 laptop:pb-0 laptop:pr-24">
              <div data-aos="fade-up">
                <SectionTitle className="tablet:w-[70%]  laptop:w-full">
                  Your business is a big part of your{" "}
                  <span className="text text-primary-600">life</span>
                </SectionTitle>
                <SectionText className="max-w-[494px]">
                  Keeping your eye on the ball while performing a deep dive on
                  the start-up mentality to derive convergence on cross-platform
                  integration.
                </SectionText>
              </div>

              {/* card-minis */}
              <div className="grid grid-cols-2 max-w-[400px] gap-4">
                <div
                  data-aos="zoom-in"
                  className="flex items-center gap-4 px-4 py-3 bg-white rounded-2xl shadow-p-md"
                >
                  <Speedometer2 className="text-primary-600 text-display-xs" />
                  <TextSmall>Project Management</TextSmall>
                </div>

                <div
                  data-aos="zoom-in"
                  className="flex items-center gap-4 px-4 py-3 bg-white rounded-2xl shadow-p-md"
                >
                  <Binoculars className="text-primary-600 text-display-xs" />

                  <TextSmall>Marketing & Dashboard</TextSmall>
                </div>

                <div
                  data-aos="zoom-in"
                  className="flex items-center gap-4 px-4 py-3 bg-white rounded-2xl shadow-p-md"
                >
                  <PieChart className="text-primary-600 text-display-xs" />
                  <TextSmall>Advanced Analytics</TextSmall>
                </div>

                <div
                  data-aos="zoom-in"
                  className="flex items-center gap-4 px-4 py-3 bg-white rounded-2xl shadow-p-md"
                >
                  <Award className="text-primary-600 text-display-xs" />

                  <TextSmall>Design and Developement</TextSmall>
                </div>
              </div>
            </div>
            {/* right-part */}
            <div className="laptop:col-span-5">
              <div className="relative inline-block ">
                <StaticImage
                  src="../../images/about-1.png"
                  alt=""
                  className="relative z-30 rounded-tl-none rounded-3xl"
                />
                {/* shape1 */}
                <div className="absolute z-10 top-0 right-0 translate-x-[15%] -translate-y-[15%] w-[95%] inline-block">
                  <div
                    data-aos="fade-up-right"
                    className="w-full bg-secondary-50 rounded-3xl rounded-br-none before:block before:content-[''] pb-[100%]"
                  >
                    <div className="absolute z-20 top-full left-full w-[68px] h-[68px] bg-neutral-100"></div>
                  </div>
                </div>
                {/* shape3 */}
                <div className="absolute bottom-full right-full">
                  <div
                    data-aos="fade-up-left"
                    data-aos-delay="400"
                    className="w-[32px] h-[32px] bg-neutral-100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default About1
