import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Award } from "react-bootstrap-icons"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"

import Button from "../../components/Button"
import ButtonOutline from "../../components/ButtonOutline"

const Hero1 = () => {
  return (
    <>
      <Section isHero className="overflow-hidden laptop:!py-[140px]">
        <Container>
          {/* section-wrap */}
          <div className="grid items-center justify-center grid-cols-1 laptop:grid-cols-2 section-wrap ">
            {/* left-part */}
            <div className="pr-0 laptop:pr-[72px] pb-20 laptop:pb-0 ">
              <SectionTitle isHero={true}>
                Take your business to the{" "}
                <span className="text text-primary-600">next level</span>
              </SectionTitle>

              <SectionText isHero={true}>
                Kloft provides everything you need to build a stunning website
                for your business. We take care of both the performance and
                aesthetic aspects.
              </SectionText>

              <div className="flex gap-3 tablet:gap-5 button-wrap">
                <Link to="#">
                  <Button className="" size={"2xl"}>
                    Get started
                  </Button>
                </Link>
                <Link to="/pricing-1">
                  <ButtonOutline size={"2xl"}>See Pricing </ButtonOutline>
                </Link>
              </div>
            </div>

            {/* right-part */}
            <div className="">
              <div className="relative inline-block">
                <StaticImage
                  src="../../images/hero-1.png"
                  alt=""
                  className="relative z-20 rounded-3xl"
                />

                <div className="absolute top-0 left-0 z-10 -translate-x-1/3 -translate-y-1/3 shape-1">
                  <div
                    className=" shape-1 h-16 w-16 tablet:h-[100px] tablet:w-[100px] bg-secondary-100"
                    data-aos="fade-up-left"
                    data-aos-delay="800"
                  ></div>
                </div>
                <div className="absolute bottom-0 right-0 z-30 translate-x-1/3 translate-y-1/3">
                  <div
                    className="shape-2 h-16 w-16 tablet:h-[100px] tablet:w-[100px] bg-primary-100 rounded-full"
                    data-aos="fade-up-left"
                    data-aos-delay="600"
                  ></div>
                </div>
                <div className="absolute bottom-0 z-30 -translate-y-1/2 tablet:-translate-y-3/4 -left-2 tablet:-left-10 flot-card">
                  <div className="flex items-center justify-center gap-2 px-5 py-3 bg-white rounded-lg shadow-md">
                    <Award className="text-primary-600 text-display-xs" />
                    <p className="font-normal text-neutral-700 card-text text-body-md">
                      Award winning design
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Hero1
