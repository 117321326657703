import React from "react"

import BrandLogo1 from "../../images/svg/brand-logo-1.svg"
import BrandLogo2 from "../../images/svg/brand-logo-2.svg"
import BrandLogo3 from "../../images/svg/brand-logo-3.svg"
import BrandLogo4 from "../../images/svg/brand-logo-4.svg"
import BrandLogo5 from "../../images/svg/brand-logo-5.svg"

const Brand1 = () => {
  return (
    <>
      <section>
        <div className="max-w-[1000px] mx-auto px-5 tablet:px-16 laptop:px-0">
          <div className="flex flex-wrap items-center justify-center gap-12 px-10 py-10 bg-secondary-50 rounded-3xl">
            <BrandLogo1></BrandLogo1>
            <BrandLogo2></BrandLogo2>
            <BrandLogo3></BrandLogo3>
            <BrandLogo4></BrandLogo4>
            <BrandLogo5></BrandLogo5>
          </div>
        </div>
      </section>
    </>
  )
}

export default Brand1
