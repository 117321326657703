import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Button from "../../components/Button"
import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import BlogCard1 from "../../components/blogs/BlogCard1"

const Blog1 = () => {
  const { image1, image2, image3 } = useStaticQuery(graphql`
    query {
      image1: allFile(filter: { relativePath: { eq: "blog-1.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image2: allFile(filter: { relativePath: { eq: "blog-2.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image3: allFile(filter: { relativePath: { eq: "blog-3.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `)
  return (
    <>
      <Section className="">
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col gap-12 tablet:gap-14 laptop:gap-[82px]">
            {/* top-part */}
            <div
              data-aos="fade-in"
              className="flex flex-col items-center justify-center"
            >
              <SectionTitle>
                Latest <span className="text text-primary-600">news</span>
              </SectionTitle>
              <Link to="blog-1">
                <Button className="" size={"xl"}>
                  Explore Blog
                </Button>
              </Link>
            </div>

            {/* bottom-part */}
            <div className="">
              {/* cards */}
              <div className="grid grid-cols-1 gap-8 tablet:grid-cols-2 laptop:grid-cols-3">
                {/* card */}
                <BlogCard1
                  title="Why do your customers become unhappy?"
                  text="Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive."
                  imageData={image1.nodes[0]}
                  tag="Technology"
                  date="Feb 27, 2021"
                ></BlogCard1>
                <BlogCard1
                  title="The top tools for great prototyping"
                  text="Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate."
                  imageData={image2.nodes[0]}
                  tag="Marketing"
                  date="Jan 15, 2021"
                ></BlogCard1>
                <BlogCard1
                  title="Starting a business: tips for entrepreneurs"
                  text="Quickly maximize timely deliverables for real-time schemas. Dramatically maintain clicks-and-mortar solutions."
                  imageData={image3.nodes[0]}
                  tag="Business"
                  date="Feb 20, 2021"
                ></BlogCard1>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Blog1
