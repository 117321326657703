import React from "react"
import { PlayFill } from "react-bootstrap-icons"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionText from "../../components/SectionText"

const Video1 = ({ className }) => {
  return (
    <>
      <Section className={`max-w-[888px] mx-auto ${className} `}>
        <Container>
          {/* top-part */}
          <div className="pb-10 tablet:pb-14">
            <div
              data-aos="fade-up"
              className="flex flex-col w-full gap-5 tablet:gap-7 tablet:w-9/12 laptop:w-full laptop:flex-row laptop:gap-14"
            >
              <h5 className="font-bold text-neutral-800 text-display-xs tablet:text-display-sm">
                Discover how we can help you accelerate your business
              </h5>
              <SectionText className="!pb-0">
                Bring to the table win-win survival strategies to ensure
                proactive domination. At the end of the day.
              </SectionText>
            </div>
          </div>
          <div
            className="relative inline-block rounded-3xl bottom-part"
            data-aos="zoom-in"
          >
            <StaticImage
              className="h-[200px] rounded-2xl tablet:h-auto"
              src="../../images/video-image-1.png"
              alt=""
            />
            {/* play-btn */}
            <div className="animate-pulse">
              <a
                href="https://www.youtube.com/watch?v=IUN664s7N-c"
                target="_blank"
                rel="noreferrer"
                className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 Shape h-11 tablet:h-[72px] w-11 tablet:w-[72px] rounded-full hover:bg-primary-600 bg-white shadow-md flex justify-center items-center text-black hover:text-white duration-300"
              >
                <PlayFill className="text-display-xs tablet:text-display-sm" />
              </a>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Video1
