import React from "react"
import { Link } from "gatsby"

import Button from "../../components/Button"
import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionText from "../../components/SectionText"

const Cta1 = () => {
  return (
    <>
      <Section className="overflow-hidden !pb-0 !pt-0 tablet:!pt-10">
        <Container>
          {/* section-wrap */}
          <div className="relative ">
            <div
              data-aos="zoom-in"
              className="flex flex-col tablet:flex-row items-center justify-center gap-6 tablet:gap-10 laptop:gap-[216px] px-6 tablet:px-20 laptop:px-[120px] py-6 tablet:py-12 laptop:py-[80px] max-w-[1008px]  mx-auto rounded-3xl bg-primary-600"
            >
              <div className="flex flex-col gap-4 laptop:gap-6 ">
                <h5 className="font-bold text-center text-white tablet:text-left text-display-sm laptop:text-display-md">
                  Get started now
                </h5>
                <SectionText className="text-neutral-300 !pb-0 text-center tablet:text-left max-w-[400px] ">
                  Build a custom website without the stress, easily customised
                  to suit your need.
                </SectionText>
              </div>
              <Link to="contact-2">
                <Button
                  size={"xl"}
                  className="!bg-neutral-200 text-neutral-700 hover:!bg-white border-neutral-300"
                >
                  Contact us
                </Button>
              </Link>
            </div>
            <div className="absolute -top-[20%] -right-[20%] laptop:-right-[2%] -z-10">
              <div
                data-aos="fade-up-right"
                data-aos-delay="500"
                className=" h-[250px] w-[250px] bg-neutral-50 "
              ></div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Cta1
