import React from "react"

const StateCard1 = ({
  title = "98%",
  text = "Customer Satisfaction",
  icon = "",
  iconClassName = "",
  className = "",
}) => {
  return (
    <>
      <div
        className={`flex flex-col items-center justify-center gap-5 ${className}`}
      >
        <div className={`text-secondary-400 text-display-md ${iconClassName}`}>
          {icon}
        </div>
        <div className="flex flex-col gap-3">
          <h6 className="font-bold text-center text-display-sm text-neutral-50">
            {title}
          </h6>
          <p className="font-medium text-center text-body-xl text-neutral-400">
            {text}
          </p>
        </div>
      </div>
    </>
  )
}
export default StateCard1
