import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import Hero1 from "../sections/heroes/Hero1"
import Brand1 from "../sections/brands/Brand1"
import Video1 from "../sections/videos/Video1"
import Feature1 from "../sections/features/Feature1"
import About1 from "../sections/abouts/About1"
import Testimonial1 from "../sections/testimonials/Testimonial1"
import State1 from "../sections/states/State1"
import Team1 from "../sections/teams/Team1"
import Blog1 from "../sections/blogs/Blog1"
import Cta1 from "../sections/ctas/Cta1"

const IndexPage = ({ data }) => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          bottomWrapperClassName: true,
          hasMiddleSocial: true,
          hasBottomSubscription: true,
          bottomClassName: "bg-primary-25",
        }}
      >
        <GatsbySeo
          title={`Kloft - Home 01`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <Hero1 />
        <Brand1 />
        <Video1 />
        <Feature1 />
        <About1 />
        <Testimonial1 />
        <State1 />
        <Team1 />
        <Blog1 />
        <Cta1 />
      </PageWrapper>
    </>
  )
}

export default IndexPage
